import React, { useState, useEffect } from "react"
import type { ComponentType } from "react"
import { themeSwicther } from "./Dark_Light_Mode.tsx"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

export function promptsStyles(Component): ComponentType {
    return (props) => {
        const [localData, setLocalData] = useState(
            localStorage.getItem("currentToggleState") || "dark"
        )

        useEffect(() => {
            const handleThemeChange = () => {
                const newTheme = localStorage.getItem("currentToggleState")
                if (newTheme !== localData) {
                    setLocalData(newTheme)
                }
            }

            window.addEventListener("themeChange", handleThemeChange)

            return () => {
                window.removeEventListener("themeChange", handleThemeChange)
            }
        }, [localData])

        useEffect(() => {
            const promptsEl = document.querySelectorAll("h6 code")

            promptsEl.forEach((code) => {
                const divElement = document.createElement("div")
                divElement.innerHTML = code.innerHTML
                code.parentNode.replaceChild(divElement, code)
            })

            const divElements = document.querySelectorAll("h6 div")

            const imgElements = document.querySelectorAll("div img")

            imgElements.forEach((imgElement) => {
                imgElement.style.width = "100%"
            })

            divElements.forEach((divElement) => {
                divElement.style.display = "inline-block"
                divElement.style.fontWeight = "400"
                divElement.style.fontSize = "20px"
                divElement.style.lineHeight = "1.2em"
                divElement.style.textAlign = "start"
                divElement.style.borderRadius = "6px"
                divElement.style.padding = "0.15em 0.3em"
                divElement.style.borderWidth = "1px"
                divElement.style.borderStyle = "solid"
                divElement.style.borderColor =
                    localData === "dark"
                        ? "rgba(244, 240, 240, 1)"
                        : "rgba(255, 255, 255, 0.05)"
                divElement.style.color =
                    localData === "dark" ? "#262626" : "#E5E5E5"
                divElement.style.background =
                    localData === "dark" ? "#CCCCCC" : "#222222"
            })
        }, [localData])

        return <Component {...props} />
    }
}

export default themeSwicther(promptsStyles)
